<template>
  <content-layout
    :loading="loading"
    :breadcrumbs-items="breadcrumbsItems"
    search-enabled
    :search-text.sync="searchText"
    class="px-4"
  >
    <template #header>
      <v-toolbar flat color="background" class="mt-2">
        <cz-button
          :title="$t('admin.createNewCustomer')"
          color="button"
          dark
          :icon-src="mdiPlusCircleOutline"
          @click="newCustomerDialog.show = true"
        />
      </v-toolbar>
    </template>
    <cz-data-table
      v-bind="customersTable"
      :items="filteredCustomers"
      height="calc(100vh - 250px)"
    >
      <template #item.contactPerson="{ item }">
        <cz-menu-button
          flat
          :button-title="item.contactPersonName"
          color="primary darken-1"
          :menu-items="customerMenuItems(item)"
        />
      </template>
      <template #item.activeFeatures="{ item }">
        <div class="d-flex align-center flex-wrap py-3" style="gap: 10px">
          <cz-chip
            v-for="feature in item.activeFeaturesTexts"
            :key="feature"
            :text="feature.name"
            color="buttonSecondary"
            small
            removeable
            @remove="onRemoveFeatureClicked(item, feature)"
          />
        </div>
      </template>
      <template #item.actions="{ item }">
        <cz-menu-button
          :button-icon-src="mdiDotsHorizontalCircleOutline"
          flat
          :menu-items="actionsMenuItems"
          @action:clicked="onActionClicked($event, item)"
          clickable
        />
      </template>
    </cz-data-table>
    <cz-prompt-dialog
      v-if="removeFeatureDialog.show"
      v-model="removeFeatureDialog.show"
      v-bind="removeFeatureDialog"
      @cancel="removeFeatureDialog.show = false"
      @done="removeFeatureFromAccount"
    />
    <manage-account-features-dialog
      v-if="manageAccountFeaturesDialog.show"
      v-model="manageAccountFeaturesDialog.show"
      v-bind="manageAccountFeaturesDialog"
      @close="manageAccountFeaturesDialog.show = false"
      @save="saveAccountFeatures"
    />

    <new-customer-dialog
      v-model="newCustomerDialog.show"
      v-if="newCustomerDialog.show"
      v-bind="newCustomerDialog"
      @close="newCustomerDialog.show = false"
      @on-create="createNewCustomer"
    />
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout';
import {
  CzDataTable,
  CzMenuButton,
  CzButton,
  CzChip,
  CzPromptDialog
} from '@/components';
import { getAccounts, updateAccount, createAccount } from '@/core/api';
import ManageAccountFeaturesDialog from '../components/ManageAccountFeaturesDialog.vue';
import NewCustomerDialog from '../components/NewCustomerDialog.vue';
import { mapActions } from 'vuex';
import {
  mdiCircleEditOutline,
  mdiDotsHorizontalCircleOutline,
  mdiShapePlusOutline,
  mdiPlusCircleOutline
} from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
export default {
  name: 'CustomersListPage',
  components: {
    ContentLayout,
    CzDataTable,
    CzMenuButton,
    CzButton,
    CzChip,
    CzPromptDialog,
    ManageAccountFeaturesDialog,
    NewCustomerDialog
  },
  created() {
    this.loadData();
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.$t('admin.adminHomePage'),
          to: {
            name: 'super-admin-home'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.$t('admin.customersList'),
          disabled: true,
          exact: true
        }
      ];
    },
    filteredCustomers() {
      if (!this.searchText) {
        return this.customersTable.items;
      }

      const searchLowerCase = this.searchText.toLowerCase();

      return this.customersTable.items.filter(
        (item) =>
          item.companyName?.toLowerCase().includes(searchLowerCase) ||
          item.contactPersonName?.toLowerCase().includes(searchLowerCase) ||
          item.businessId?.toLowerCase().includes(searchLowerCase) ||
          item.authorizedDomain?.toLowerCase().includes(searchLowerCase)
      );
    },
    actionsMenuItems() {
      return [
        {
          id: 'add-feature',
          title: this.$t('admin.manageAccountFeaturesTitle'),
          icon: mdiCircleEditOutline
        },
        {
          id: 'workflow-management',
          title: this.$t('admin.manageAccountWorkflow'),
          icon: mdiShapePlusOutline
        }
      ];
    }
  },
  data() {
    return {
      mdiCircleEditOutline,
      mdiDotsHorizontalCircleOutline,
      mdiShapePlusOutline,
      mdiPlusCircleOutline,
      searchText: '',
      loading: false,
      newCustomerDialog: {
        show: false,
        loading: false
      },
      removeFeatureDialog: {
        show: false,
        message: null,
        doneButtonTitle: this.$t('common.remove'),
        title: this.$t('admin.removeFeatureDialog.title'),
        account: null,
        feature: null,
        loading: false
      },
      manageAccountFeaturesDialog: {
        show: false,
        account: null,
        loading: false
      },
      customersTable: {
        loading: false,
        items: [],
        columns: [
          {
            text: this.$t('admin.customerName'),
            value: 'companyName',
            width: 150
          },
          {
            text: this.$t('admin.businessId'),
            value: 'businessId',
            width: 230
          },
          {
            text: this.$t('admin.authorizedDomain'),
            value: 'authorizedDomain',
            width: 150
          },
          {
            text: this.$t('admin.contactPerson'),
            value: 'contactPerson'
          },
          {
            text: this.$t('customer.supportNumber'),
            value: 'supportNumber',
            width: 250
          },
          {
            text: this.$t('customer.activeFeatures'),
            value: 'activeFeatures',
            width: 500
          },
          {
            text: this.$t('common.actions'),
            value: 'actions'
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    async loadData() {
      try {
        this.customersTable.loading = true;
        const { data: accounts } = await getAccounts({
          accountType: 'customer'
        });
        this.customersTable.items = accounts;
      } finally {
        this.customersTable.loading = false;
      }
    },
    customerMenuItems(item) {
      return [
        {
          title: this.$t('admin.contactPersonName'),
          subtitle: item.contactPersonName
        },
        {
          title: this.$t('admin.contactPersonEmail'),
          subtitle: item.contactPersonEmail
        },
        {
          title: this.$t('admin.contactPersonPhone'),
          subtitle: item.contactPersonPhone
        }
      ];
    },
    async createNewCustomer(account) {
      try {
        this.newCustomerDialog.loading = true;
        const createdAccount = await createAccount({
          ...account,
          accountType: 'customer'
        });
        this.newCustomerDialog.show = false;
        this.showSuccessToastMessage(
          this.$t('admin.newCustomerCreatedSuccessMessage', [
            account.accountOwnerEmail
          ])
        );
        const _items = cloneDeep(this.customersTable.items);
        _items.unshift(createdAccount);
        this.customersTable.items = _items;
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.newCustomerDialog.loading = false;
      }
    },
    onRemoveFeatureClicked(account, feature) {
      this.removeFeatureDialog.account = cloneDeep(account);
      this.removeFeatureDialog.feature = feature;
      this.removeFeatureDialog.message = this.$t(
        'admin.removeFeatureDialog.message',
        [feature.name, account.companyName]
      );
      this.removeFeatureDialog.show = true;
    },
    async removeFeatureFromAccount() {
      try {
        this.removeFeatureDialog.loading = true;
        const updatedAccount = await updateAccount(
          this.removeFeatureDialog.account._id,
          {
            $pull: { activeFeatures: this.removeFeatureDialog.feature.value }
          }
        );

        this.removeFeatureDialog.show = false;

        const index = this.customersTable.items.findIndex(
          (item) => item._id === updatedAccount._id
        );

        const _items = cloneDeep(this.customersTable.items);

        if (index !== -1) {
          _items[index] = updatedAccount;
        }
        this.customersTable.items = _items;

        this.showSuccessToastMessage(
          this.$t('admin.removeFeatureDialog.successMessage', [
            this.removeFeatureDialog.feature.name,
            updateAccount.companyName
          ])
        );
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.removeFeatureDialog.loading = false;
      }
    },
    onActionClicked(action, account) {
      if (action.id === 'add-feature') {
        this.manageAccountFeaturesDialog.account = account;
        this.manageAccountFeaturesDialog.show = true;
      } else if (action.id === 'workflow-management') {
        this.$router.push({
          name: 'super-admin-workflow-management',
          params: {
            defaultAccountId: account._id
          }
        });
      }
    },
    async saveAccountFeatures({ account, accountFeatures: activeFeatures }) {
      // const activeFeatures = accountFeatures
      //   .filter((item) => !!item.value)
      //   .map((item) => item.name);

      try {
        this.manageAccountFeaturesDialog.loading = true;
        const updatedAccount = await updateAccount(account._id, {
          activeFeatures
        });

        this.manageAccountFeaturesDialog.show = false;
        const index = this.customersTable.items.findIndex(
          (item) => item._id === updatedAccount._id
        );

        const _items = cloneDeep(this.customersTable.items);

        if (index !== -1) {
          _items[index] = updatedAccount;
        }
        this.customersTable.items = _items;

        this.showSuccessToastMessage(
          this.$t('admin.manageAccountFeaturesDialog.successMessage', [
            account.companyName
          ])
        );
      } finally {
        this.manageAccountFeaturesDialog.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
