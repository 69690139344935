<template>
  <cz-dialog
    :title="$t('admin.createNewCustomer')"
    v-on="$listeners"
    v-bind="$attrs"
    :width="800"
    :height="400"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form
        class="pa-6"
        @submit.prevent="$emit('on-create', form)"
        :disabled="loading"
      >
        <v-row>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.customerName')" required>
              <validation-provider
                rules="required"
                :name="$t('admin.customerName')"
                v-slot="{ errors }"
              >
                <cz-input v-model="form.name" :error-messages="errors" />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.customerPrefix')" required>
              <validation-provider
                rules="required"
                :name="$t('admin.customerPrefix')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.prefix"
                  :hint="$t('admin.customerPrefixHint')"
                  :error-messages="errors"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.activeFeatures')" required>
              <validation-provider
                :name="$t('admin.activeFeatures')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="form.activeFeatures"
                  multiple
                  :items="[
                    {
                      text: $t('admin.accountFeatures.order_creation'),
                      value: 'order_creation'
                    },
                    {
                      text: $t(
                        'admin.accountFeatures.order_creation_with_items'
                      ),
                      value: 'order_creation_with_items'
                    },
                    {
                      text: $t('admin.accountFeatures.digital_archive_orders'),
                      value: 'digital_archive_orders'
                    },
                    {
                      text: $t(
                        'admin.accountFeatures.digital_archive_submissions'
                      ),
                      value: 'digital_archive_submissions'
                    },
                    {
                      text: $t('admin.accountFeatures.invoices'),
                      value: 'orderless'
                    }
                  ]"
                  :error-messages="errors"
                  item-value="value"
                  item-text="text"
                  small-chips
                  item-color="textPrimary"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.businessId')" required>
              <validation-provider
                rules="required|length:9"
                :name="$t('admin.businessId')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.businessId"
                  :error-messages="errors"
                  type="number"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>

          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.customerAdminEmail')" required>
              <validation-provider
                rules="required|email"
                :name="$t('admin.customerAdminEmail')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.accountOwnerEmail"
                  :error-messages="errors"
                  type="email"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.authorizedDomain')" required>
              <validation-provider
                rules="required|domain"
                :name="$t('admin.authorizedDomain')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.authorizedDomain"
                  :error-messages="errors"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.contactPersonName')" required>
              <validation-provider
                rules="required"
                :name="$t('admin.contactPersonName')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.contactPersonName"
                  :error-messages="errors"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.contactPersonEmail')" required>
              <validation-provider
                rules="required|email"
                :name="$t('admin.contactPersonEmail')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.contactPersonEmail"
                  :error-messages="errors"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
          <v-col :lg="6" :md="6" :sm="12">
            <cz-form-field :label="$t('admin.contactPersonPhone')" required>
              <validation-provider
                rules="required|phoneNumber"
                :name="$t('admin.contactPersonPhone')"
                v-slot="{ errors }"
              >
                <cz-input
                  v-model="form.contactPersonPhone"
                  :error-messages="errors"
                  type="tel"
                />
              </validation-provider>
            </cz-form-field>
          </v-col>
        </v-row>

        <cz-button
          color="info"
          type="submit"
          class="mt-4 font-weight-bold"
          :disabled="invalid"
          width="100%"
          x-large
          :loading="loading"
          :title="$t('admin.createCustomerButtonTitle')"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzInput,
  CzButton,
  CzFormField,
  CzAutocomplete
} from '@/components';
export default {
  name: 'NewCustomerDialog',
  components: {
    CzDialog,
    CzInput,
    CzFormField,
    CzAutocomplete,
    CzButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        prefix: '',
        accountOwnerEmail: '',
        authorizedDomain: '',
        contactPersonName: '',
        contactPersonEmail: '',
        contactPersonPhone: '',
        websiteUrl: ''
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
