import { render, staticRenderFns } from "./NewCustomerDialog.vue?vue&type=template&id=6000fbff&scoped=true"
import script from "./NewCustomerDialog.vue?vue&type=script&lang=js"
export * from "./NewCustomerDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6000fbff",
  null
  
)

export default component.exports